import React from "react";
import "./hero.css"; // Assuming you have a CSS file for custom styles

export default function Hero() {
  return (
    <div>
      <div>
        <section className="py-5 position-relative hero-section-home"></section>
      </div>
      <div className="hero-container">
        <div className="hero-header text-center ">
          <img
            className="logo-image d-block mx-auto mb-4 "
            src="\Images\logo.png"
            alt="AHS Global Exim Logo"
            width="140"
            height="140"
          />
          <div className="col-lg-8 mx-auto">
            <p className="lead mb-4 hero-intro">
              Welcome to AHS GLOBAL EXIM, your trusted gateway for high-quality
              import and export services. Specializing in the trade of premium
              turmeric products & spices, frozen foods, and fresh edible
              vegetables, we are committed to delivering excellence and
              fostering global connections.
            </p>
          </div>
        </div>
        <div
          className="container p-5 rounded "
          style={{ backgroundColor: "#CFE1A0" }} /* Light gray background */
        >
          <div className="row align-items-center">
            <div className="col-lg-6 mb-4">
              <h2 className="section-title d-flex align-items-center">
                <i className="fa-solid fa-leaf icon"></i>
                OUR TURMERIC POWDER
              </h2>
              <p className="section-description">
                Our turmeric powder is renowned for its deep, earthy aroma and
                robust flavor, adding vibrant color and a unique taste to your
                dishes. Ideal for traditional curries, hearty soups, and
                marinades, it enhances both taste and visual appeal. Exported
                globally, our premium turmeric, sourced directly from trusted
                farms, comes in a range of curcumin contents from 5% to 6%, with
                options including 1%, 2%, 3%, and 3.5%. Elevate your health and
                culinary creations with our exceptional turmeric products.
              </p>
            </div>
            <div className="col-lg-6 mb-4">
              <img
                src="/Images/turmericmain.png"
                className="img-fluid rounded shadow"
                alt="Turmeric"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
