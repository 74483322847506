import React from "react";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
          <div className="col-md-4 d-flex align-items-center">
            <span className="mb-3 mb-md-0 text-body-secondary">
              © 2024 Company, ahsglobalexim.com
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}
