import React from "react";
import Hero from "../Component/Hero";
import Product from "../Component/Product";
import Features from "../Component/Features";
import Contactbtn from "../Component/Contactbtn";

export default function Home() {
  return (
    <div>
      <Hero />
      <Product />
      <Features />
      <br />
      <Contactbtn />
    </div>
  );
}
