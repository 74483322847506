import React from "react";
import "./feature.css"; // Make sure to add this CSS file

export default function Features() {
  return (
    <div>
      <section className="pt-0 pt-xl-5">
        <div className="container">
          <div className="row mb-3 mb-sm-4">
            <div className="col-12 text-center">
              <h1 className="headings">Why Choose Us</h1>
            </div>
          </div>

          <div className="row g-4">
            <div className="col-sm-6 col-lg-4">
              <div className="card card-body feature-card">
                <div className="icon-lg bg-opacity-10 mb-4">
                  <i
                    className="fa-solid fa-leaf fa-2xl"
                    style={{ color: "#a2c520" }}
                  ></i>
                </div>
                <h5 className="card-title">Premium Quality Products</h5>
                <p className="mb-0">
                  At AHS GLOBAL EXIM, we pride ourselves on offering only the
                  highest quality turmeric and other products. Our commitment to
                  excellence ensures that every product you receive is of
                  premium quality, sourced from the best origins, and
                  meticulously processed to retain its natural goodness and
                  potency.
                </p>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="card card-body feature-card">
                <div className="icon-lg bg-opacity-10 mb-4">
                  <i
                    className="fa-solid fa-leaf fa-2xl"
                    style={{ color: "#a2c520" }}
                  ></i>
                </div>
                <h5 className="card-title">Safety and Certified</h5>
                <p className="mb-0">
                  Your health and safety are our top priorities. At AHS GLOBAL
                  EXIM, we adhere to the highest standards of safety and quality
                  control to provide you with products that you can trust. Our
                  products are FSSAI Certified, meet Agmark standards, and are
                  NABL Lab Certified.
                </p>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="card card-body feature-card">
                <div className="icon-lg bg-opacity-10 mb-4">
                  <i
                    className="fa-solid fa-leaf fa-2xl"
                    style={{ color: "#a2c520" }}
                  ></i>
                </div>
                <h5 className="card-title">Quality You Can Trust</h5>
                <p className="mb-0">
                  At AHS GLOBAL EXIM, quality is at the heart of everything we
                  do. Our turmeric and other products undergo rigorous quality
                  control measures to ensure they meet the highest standards.
                  From farm to your doorstep, we guarantee purity, potency, and
                  premium quality.
                </p>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="card card-body feature-card">
                <div className="icon-lg bg-opacity-10 mb-4">
                  <i
                    className="fa-solid fa-leaf fa-2xl"
                    style={{ color: "#a2c520" }}
                  ></i>
                </div>
                <h5 className="card-title">Customer-Centric Approach</h5>
                <p className="mb-0">
                  Your satisfaction is our priority. We strive to build lasting
                  relationships with our customers by offering personalized
                  service, timely deliveries, and responsive support. We listen
                  to your needs and tailor our services to exceed your
                  expectations.
                </p>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="card card-body feature-card">
                <div className="icon-lg bg-opacity-10 mb-4">
                  <i
                    className="fa-solid fa-leaf fa-2xl"
                    style={{ color: "#a2c520" }}
                  ></i>
                </div>
                <h5 className="card-title">Global Reach, Local Touch</h5>
                <p className="mb-0">
                  As a global import-export business, we have the capability to
                  reach markets worldwide while maintaining a local touch.
                  Whether you're a small business or a large corporation, we
                  cater to your specific needs with agility and precision.
                </p>
              </div>
            </div>

            <div className="col-sm-6 col-lg-4">
              <div className="card card-body feature-card">
                <div className="icon-lg bg-opacity-10 mb-4">
                  <i
                    className="fa-solid fa-leaf fa-2xl"
                    style={{ color: "#a2c520" }}
                  ></i>
                </div>
                <h5 className="card-title">Competitive Pricing</h5>
                <p className="mb-0">
                  We believe in providing value without compromising on quality.
                  Our efficient supply chain and strategic partnerships enable
                  us to offer competitive pricing, ensuring you get the best
                  deal for top-tier products.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
