import React from "react";
import "./aboutus.css";

export default function AboutUs() {
  return (
    <div className="container py-5">
      <div className="text-center mb-5">
        <h1 className="headings">About Us</h1>
        <p className="lead">
          Welcome to AHS GLOBAL EXIM, where we bridge the gap between markets
          and opportunities. Driven by a passion for global trade and a
          commitment to excellence, AHS GLOBAL EXIM is your trusted partner for
          import and export solutions.
        </p>
      </div>

      <div className="mb-5 text-content">
        <h2 className="mb-3 d-flex align-items-center">
          <i
            className="fa-solid fa-bullseye"
            style={{
              color: "#a2c520",
              marginRight: "10px",
              fontSize: "1.5rem",
            }}
          ></i>
          Our Mission
        </h2>
        <p className="content">
          Our mission is to seamlessly connect businesses across the globe by
          providing top-notch import and export services. We strive to empower
          our clients with the best products and services, ensuring their
          success in the global marketplace.
        </p>
      </div>

      <div className="mb-5 text-content">
        <h2 className="mb-3 d-flex align-items-center">
          <i
            className="fa-solid fa-eye"
            style={{
              color: "#a2c520",
              marginRight: "10px",
              fontSize: "1.5rem",
            }}
          ></i>
          Our Vision
        </h2>
        <p className="content">
          Our vision is to be a global leader in the agricultural industry,
          known for our commitment to quality, innovation, and sustainability.
          We strive to build lasting relationships with our customers,
          suppliers, and the communities we serve.
        </p>
      </div>

      <div className="mb-5 text-content">
        <h2 className="mb-3 d-flex align-items-center">
          <i
            className="fa-solid fa-hand-holding-heart"
            style={{
              color: "#a2c520",
              marginRight: "10px",
              fontSize: "1.5rem",
            }}
          ></i>
          Our Values
        </h2>
        <p className="content">
          Our vision is to be a world leader in the import-export industry,
          recognized for our reliability, innovation, and dedication to customer
          satisfaction. We aim to create a global network where businesses can
          thrive and grow through efficient and effective trade solutions.
        </p>
      </div>

      <section className="mb-lg-9 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 xol-12">
              <div className="text-center mb-7">
                <h2 className="mt-3 headings">
                  <i
                    className="fa-solid fa-users"
                    style={{
                      color: "#a2c520",
                      marginRight: "10px",
                      fontSize: "1.5rem",
                    }}
                  ></i>
                  Our Leadership
                </h2>
              </div>
            </div>
          </div>

          <section className="founder-section my-5">
            <div className="container ">
              <div className="row align-items-center">
                <div className="col-12 col-md-6">
                  <div className="founder-thoughts">
                    <h2>About Our Founder</h2>
                    <p>
                      Our founder, Harshal Ajetrao, has always believed in
                      delivering quality products that meet the highest
                      standards. With a vision to provide premium quality spices
                      and other products globally, Harshal has led our company
                      to new heights.
                    </p>
                    <p className="quote">
                      "Quality is never an accident; it is always the result of
                      high intention, sincere effort, intelligent direction, and
                      skillful execution."
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6 text-center">
                  <img
                    src="/Images/founder1.png"
                    className="img-fluid founder-image"
                    alt="Founder"
                  />
                  <h3 className="founder-name mt-3">Harshal Ajetrao</h3>
                  <p className="founder-designation">Founder & CEO</p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}
