import React from "react";
import "./products.css"; // Ensure this file is properly imported
import { NavLink } from "react-router-dom";

export default function Products() {
  return (
    <div className="container ">
      <div className="hero-container">
        <div className="hero-header text-center ">
          <img
            className="logo-image d-block mx-auto mb-4"
            src="/Images/logo.png"
            alt="AHS Global Exim Logo"
            width="140"
            height="140"
          />
          <div className="col-lg-8 mx-auto">
            <p className="lead mb-4 hero-intro">
              Welcome to AHS GLOBAL EXIM, your trusted gateway for high-quality
              import and export services. Specializing in the trade of premium
              turmeric products & spices, frozen foods, and fresh edible
              vegetables, we are committed to delivering excellence and
              fostering global connections.
            </p>
          </div>
        </div>
      </div>

      <div className="row mb-5 p-5" style={{ backgroundColor: "#CFE1A0" }}>
        <div className="col-lg-6 mb-4">
          <h2 className="main-heading mb-4">Premium Turmeric Powder</h2>
          <p className="description mb-4">
            Discover our premium turmeric powder and dry turmeric fingers,
            sourced from trusted farms. Elevate your health and culinary
            creations with our top-quality products. Available options include
            1%, 2%, 3%, 3.5%, 5%, and 6% curcumin content.
          </p>
          <p className="description">
            Benefits: Helps prevent cancer with anti-inflammatory and
            antioxidant properties, supports heart health, and assists in
            depression treatment while improving skin health.
          </p>
        </div>
        <div className="col-lg-6 mb-4">
          <NavLink to="/contact" className="product-link">
            <img
              src="/Images/Turmeric1.png"
              className="product-image img-fluid rounded shadow-lg"
              alt="Turmeric Powder"
              loading="lazy"
            />{" "}
          </NavLink>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-4">
          <NavLink to="/contact" className="product-link">
            <section className="product-details">
              <h2 className="section-heading">Frozen French Fries</h2>
              <img
                src="/Images/fries.png"
                className="product-image"
                alt="Frozen French Fries"
                loading="lazy"
              />
              <p className="section-description">
                Enjoy our premium Frozen French Fries, made from select potatoes
                and processed to ensure crispiness and a golden finish.
                Available in 6mm, 9mm, and 11mm sizes, they offer versatility
                for any dish. With a 12-month shelf life, our fries meet top
                food safety standards and are free from artificial additives.
                Ideal for frying, baking, or air frying, they deliver
                exceptional taste and quality every time.
              </p>
            </section>
          </NavLink>
        </div>

        <div className="col-md-6 mb-4">
          <NavLink to="/contact" className="product-link">
            <section className="product-details">
              <h2 className="section-heading">Spices</h2>
              <img
                src="\Images\Spices.png"
                className="product-image"
                alt="Spices"
                loading="lazy"
              />
              <p className="section-description">
                Explore our premium selection of aromatic spices, meticulously
                sourced from the finest regions for superior quality and flavor.
                Our range includes Cardamom, Cumin Seeds, Black Pepper, Cloves,
                Dry Chillies, and Coriander Seeds. Each spice is carefully
                processed to retain its essential oils and freshness. Enhance
                your dishes with our top-grade spices, perfect for any culinary
                creation. Enjoy unparalleled taste and aroma with every use.
              </p>
            </section>
          </NavLink>
        </div>

        <div className="col-md-6 mb-4">
          <NavLink to="/contact" className="product-link">
            <section className="product-details">
              <h2 className="section-heading">Groundnuts and Ginger</h2>
              <img
                src="/Images/g&g.png"
                className="product-image"
                alt="Groundnuts and Ginger"
                loading="lazy"
              />
              <p className="section-description">
                Ginger, scientifically known as Zingiber officinale, is a
                flowering plant whose rhizome (ginger root) is widely used as a
                spice and folk medicine. Key Features: Flavor Profile - Warm,
                spicy, and aromatic, with a slightly sweet undertone. Groundnuts
                (peanuts) are versatile and nutritious, used in various dishes
                and products like peanut butter, oil, and flour.
              </p>
            </section>
          </NavLink>
        </div>

        <div className="col-md-6 mb-4">
          <NavLink to="/contact" className="product-link">
            <section className="product-details">
              <h2 className="section-heading">Other Products</h2>
              <img
                src="/Images/other.png"
                className="product-image"
                alt="Other Products"
                loading="lazy"
              />
              <p className="section-description">
                Explore our diverse range of products tailored to meet your
                unique requirements. From high-quality raw materials to finished
                goods, AHS GLOBAL EXIM delivers excellence across various
                sectors. Products include Kesar Mango, Tea and Coffee products,
                Vegetables, and Ayurvedic Pharma Products.
              </p>
            </section>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
