import React from "react";
import { NavLink } from "react-router-dom";
// Assuming you have a CSS file for this component

export default function Product() {
  return (
    <section className="product-section">
      <div className="container text-center">
        <br />
        <h1 className="headings ">Our Other Products</h1>
        <div className="row">
          {[
            {
              src: "/Images/turmeric.png",
              alt: "Turmeric Powder",
              text: "Turmeric Powder",
            },
            {
              src: "/Images/turmericfinger.png",
              alt: "Turmeric Powder",
              text: "Turmeric Finger",
            },
            { src: "/Images/Spices.png", alt: "Spices", text: "Spices" },
            { src: "/Images/ginger.png", alt: "Ginger", text: "Ginger" },
            {
              src: "/Images/penuts.png",
              alt: "Groundnuts",
              text: "Groundnuts",
            },

            {
              src: "/Images/fries.png",
              alt: "Frozen French Fries",
              text: "Frozen French Fries",
            },
            {
              src: "/Images/vegitables.png",
              alt: "Vegetables",
              text: "Vegetables",
            },
            { src: "/Images/Fruits.jpg", alt: "Fruits", text: "Fruits" },
          ].map((product, index) => (
            <div className="col-6 col-md-4 col-lg-3 mb-4" key={index}>
              <div className="product-card">
                <img
                  src={product.src}
                  className="product-img"
                  alt={product.alt}
                />
                <div className="product-card-body">
                  <h3 className="product-card-text">{product.text}</h3>
                </div>
              </div>
            </div>
          ))}
        </div>

        <NavLink to="/products">
          <button type="button" className="btn-custom ">
            View more
          </button>
        </NavLink>
        <br />
      </div>
    </section>
  );
}
