import React, { useEffect } from "react";

export default function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleClick = () => {
    alert("Please mail us directly at ahsglobalexim@gmail.com");
  };
  return (
    <div>
      <section
        className="py-5 position-relative"
        style={{
          backgroundImage: "url('/Images/contact1.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "20em",

          overflow: "hidden",
          color: "white",
          textAlign: "center",
        }}
      ></section>

      <section
        className="py-5"
        style={{
          background: "rgb(248, 248, 248)", // Light gray background for the rest of the section
        }}
      >
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-md-6 text-center">
              <img
                src="/Images/logo.png"
                className="img-fluid mb-4"
                alt="Contact Illustration"
              />
              <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center mt-4">
                <h5 className="mb-2 mb-sm-0">Follow us on:</h5>
                <ul className="list-inline mb-0 ms-sm-2">
                  <li className="list-inline-item">
                    <a
                      className="fs-5 me-2 text-linkedin"
                      href="https://www.linkedin.com/in/harshal-ajetrao-17858215b/"
                      style={{ color: "#0077b5" }}
                    >
                      <i className="fab fa-linkedin fa-2x"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      className="fs-5 me-2 text-facebook"
                      href="https://www.facebook.com/people/AHS-Global-EXIM/61562292686241/"
                      style={{ color: "#3b5998" }}
                    >
                      <i className="fab fa-facebook fa-2x"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      className="fs-5 me-2 text-instagram"
                      href="https://www.instagram.com/ahs_global_exim/"
                      style={{ color: "#e4405f" }}
                    >
                      <i className="fab fa-instagram fa-2x"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      className="fs-5 me-2 text-whatsapp"
                      href="https://wa.me/9561512290"
                      style={{ color: "#25d366" }}
                    >
                      <i className="fab fa-whatsapp fa-2x"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <p className="mt-3">Email us at : ahsglobalexim@gmail.com</p>
            </div>
            <div className="col-md-6">
              <h2 className="text-center">Let's talk</h2>
              <p>
                To request a quote or want to meet up for coffee, contact us
                directly or fill out the form and we will get back to you
                promptly.
              </p>
              <form>
                <div className="mb-4">
                  <label htmlFor="yourName" className="form-label">
                    Your name *
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="yourName"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="emailInput" className="form-label">
                    Email address *
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="emailInput"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="mobileInput" className="form-label">
                    Mobile Number *
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="mobileInput"
                    required
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="textareaBox" className="form-label">
                    Message *
                  </label>
                  <textarea
                    className="form-control"
                    id="textareaBox"
                    rows="4"
                    required
                  ></textarea>
                </div>
                <div className="d-grid">
                  <button
                    className="btn btn-primary btn-lg"
                    type="submit"
                    onClick={handleClick}
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
          <hr className="my-5" />
        </div>
      </section>
      {/* 
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-xl-6 text-center mx-auto">
              <h1 className="headings">We're here to help!</h1>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-4 d-flex align-items-stretch mb-4">
              <div className="card shadow-sm p-4 w-100">
                <h5 className="mb-3">Our History</h5>
                <p>
                  Established in 2010, AHS Global Exim has been providing
                  top-notch services for over a decade. Our mission is to
                  deliver excellence in every project we undertake.
                </p>
              </div>
            </div>
            <div className="col-lg-4 d-flex align-items-stretch mb-4">
              <div className="card shadow-sm p-4 w-100">
                <h5 className="mb-3">Our Team</h5>
                <p>
                  Meet our dedicated team of professionals who are here to
                  assist you with all your needs. We pride ourselves on our
                  expertise and commitment to customer satisfaction.
                </p>
              </div>
            </div>
            <div className="col-lg-4 d-flex align-items-stretch mb-4">
              <div className="card shadow-sm p-4 w-100">
                <h5 className="mb-3">FAQs</h5>
                <p>
                  Have questions? Check out our FAQ section to find answers to
                  common queries and learn more about our services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="pt-5 pb-0">
        <div className="container">
          <div className="row g-4 g-md-5 mt-0 mt-lg-3 justify-content-center">
            <div className="col-lg-8 mt-lg-0">
              <div className="card card-body shadow py-5 text-center h-100">
                <h5 className="mb-3">Main Office Address</h5>
                <ul className="list-inline mb-0">
                  <li className="list-item mb-3 h5 fw-light">
                    <i className="fas fa-fw fa-map-marker-alt me-2 mt-1"></i>
                    Gavali Plot, Azad Colony, Miraj.Sangli, Maharshtra, India
                    ,416410
                  </li>
                  <li className="list-item mb-3 h5 fw-light">
                    <i className="fas fa-fw fa-phone-alt me-2"></i>
                    (+91)-9561512290
                  </li>
                  <li className="list-item mb-0 h5 fw-light">
                    <i className="far fa-fw fa-envelope me-2"></i>
                    ahsglobalexim@gmail.com
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <br />
          <hr />
          <br />
        </div>
      </section>
    </div>
  );
}
